<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g transform="translate(3.272 7.766)">
      <path
        d="M197.35,170.774a7.046,7.046,0,0,1,2.486-3.323,13.287,13.287,0,0,1,16.835-.668,11.124,11.124,0,0,1,2.656,2.651,7.441,7.441,0,0,1,.523.926,1.261,1.261,0,0,1,.042.955A4.743,4.743,0,0,1,218.8,173.1a13.473,13.473,0,0,1-8.894,4.57,13.111,13.111,0,0,1-9.468-2.8,12.918,12.918,0,0,1-1.317-1.15,6.733,6.733,0,0,1-1.772-2.6Zm10.985,5.262a5.628,5.628,0,0,0,3.788-1.213,5.007,5.007,0,0,0,1-6.751,5.512,5.512,0,0,0-7.636-1.283,4.957,4.957,0,0,0-1.575,6.469A5.113,5.113,0,0,0,208.335,176.036Z"
        transform="translate(-197.35 -164.095)"
        fill="currentColor"
      />
      <path
        d="M356.994,239.606a3.43,3.43,0,0,1-3.352-2.647,3.3,3.3,0,0,1,2.763-3.932,3.491,3.491,0,0,1,3.646,1.681,3.324,3.324,0,0,1-2.372,4.827C357.452,239.571,357.222,239.582,356.994,239.606Z"
        transform="translate(-345.701 -229.468)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
